@import 'styles/colors';
@import 'styles/general';

#sidebar {
  flex: 0 0 20rem;
  width: 20rem;
  border-right: $mondrian-border;
  display: flex;
  flex-direction: column;
  position: relative;

  h2 {
    font-weight: 450;
    margin: 2rem 0 -0.5rem;
  }

  .sidebarContent {
    flex: 1;
    padding: 1rem;
    overflow-y: scroll;

    & > *:first-child {
      padding-top: 0;
      margin-top: 0;
    }

    & > *:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .downloadButton {
    width: 100%;
    text-transform: uppercase;
    font-weight: 450;
    font-size: 1.5rem;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    flex: 0 0 4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:disabled) {
      background-color: $flat-green;
    }
  }

  h2 {
    display: flex;
    justify-content: space-between;
  }

  .topBarRowInputContainer {
    margin: 0;
  }

  .topBarRowInput {
    margin: 0;
    border-radius: 0;
    border-top: 0;
    background-color: $flat-green;

    .topBarRowInputOptionSelected {
      background-color: $flat-green-shade;
    }

    &:first-child {
      border-left: 0;
    }

    &:last-child {
      border-right: 0;
    }
  }

  #fileName {
    border: $mondrian-border;
    border-radius: 0.25rem;
    width: 100%;
    outline: none;
    padding: 0.5rem;
    font-size: 1rem;
    height: 40px;
  }
}
