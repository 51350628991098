@import 'styles/colors';
@import 'styles/general';

// general styles
body {
  margin: 0;
  font-family: 'TT Norms Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

body * {
  font-family: 'TT Norms Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'MS Sans Serif';
  src: url('../fonts/MSSansSerif.ttf');
}

@font-face {
  font-family: 'PX Sans Nouveaux';
  src: url('../fonts/px_sans_nouveaux.ttf');
}

html,
body,
#root {
  height: 100%;
  box-sizing: border-box;
}

html * {
  box-sizing: border-box;
}

input,
button {
  font-size: 1.125rem;
  outline: none;
}

input[type='search'] {
  -webkit-appearance: textfield;
}

input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

.hidden-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.input-container {
  margin-top: 1.5rem;

  & > label,
  & > .label {
    text-align: left;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 425;
    line-height: 1;
  }
}

h3.label {
  text-align: left;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 425;
  line-height: 1;
}

.button {
  height: 40px;
  flex: 0 0 auto;
  color: white;
  background: $mondrian-blue;
  letter-spacing: 1px;
  font-weight: bold;
  cursor: pointer;
  padding: 1rem 1.5rem;
  border: 0.125rem solid $off-black;
  transition: 80ms ease all;
  user-select: none;
  margin: 0;

  &:hover {
    background: $mondrian-blue-shade;
  }

  &:disabled,
  &:disabled:hover {
    background: $disabled-background;
    color: $disabled-text;
    cursor: default;
  }
}

a {
  color: black;
}

.button-row {
  display: flex;

  & > * {
    flex: 1 !important;
    margin-right: 1rem !important;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.sidebar-divider {
  border-top: $mondrian-border;
  margin: 0 -1rem;
}

.sketch-picker {
  border: $mondrian-border;
  box-shadow: none !important;
}
