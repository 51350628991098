@import './range-input-original';
@import 'styles/colors';

.sliderLabelContainer {
  display: flex;
  align-items: center;
}

.sliderValContainer {
  border: $mondrian-border;
  border-radius: 0.25rem;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  flex: 0 0 auto;
  height: 30px;
}

.sliderValInput,
.sliderVal {
  border: 0;
  outline: none;
  width: 4.5rem;
  text-align: center;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sliderValUnit {
  border-left: $mondrian-border;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
  line-height: 1;
  background: $mondrian-blue;
  width: 30px;

  span {
    color: $off-white;
    font-size: 1rem;
  }
}
