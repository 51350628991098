.iconButtonRow {
  display: flex;
  margin-top: 0.5rem;

  .iconButton {
    margin-right: 0.5rem;
    border-radius: 0.25rem;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    &:hover {
      box-shadow: inset 0 0 4px 4px rgba(0, 0, 0, 0.1);
    }

    &:last-child {
      margin: 0;
    }
  }
}

.tooltipContent * {
  text-align: center;
}
