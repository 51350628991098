@import 'styles/colors';
@import 'styles/general';

.appBody {
  flex: 1;
  position: relative;
}

.export {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  user-select: none;
  flex-shrink: 0;
}

.exportBoundary {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.exportBackground {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.zoomPanControl_container {
  position: absolute;
  top: 8px;
  right: 8px;
}

.zoomPanControl {
  height: 36px;
  width: 36px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  border: 2px solid #222;
  border-radius: 4px;
  background-color: $mondrian-blue;
  color: $off-white;
  user-select: none;
}

.zoomPanControl_label {
  position: absolute;
  top: 8px;
  right: 52px;
  height: 36px;
  width: 120px;
  padding: 0 12px;
  justify-content: space-between;
  z-index: 100;
}

.zoomPanControl_plus {
  &:hover {
    background-color: $mondrian-blue-shade;
  }

  font-size: 32px;
  padding-bottom: 2px;
  margin-bottom: 8px;
  cursor: pointer;
}

.zoomPanControl_minus {
  &:hover {
    background-color: $mondrian-blue-shade;
  }

  font-size: 38px;
  padding-bottom: 4px;
  cursor: pointer;
}
