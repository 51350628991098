@import 'styles/colors';
@import 'styles/general';

.browserWindow {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  z-index: 101;
  position: relative;

  * {
    box-sizing: inherit;
  }

  &.shadowSmall {
    box-shadow: $box-shadow-md;
  }

  &.shadowLarge {
    box-shadow: $box-shadow-lg;
  }

  .headerBar {
    background: #3d4148;
    flex: 0 0 2.5em;
    display: flex;
    align-items: center;
  }

  .addressBar {
    background: white;
    color: #222;
    flex: 1;
    border-radius: 0.25em;
    display: flex;
    align-items: center;
    margin: 0 2em;
    max-width: 100%;
    height: 1.75em;
    min-width: 0;
    cursor: text;

    &.addressNone {
      visibility: hidden;
    }
  }

  .address {
    display: inline-block;
    height: 1.5em;
    line-height: 1.5em;
    vertical-align: middle;
    margin: 0 0.5em;
    width: 100%;
    flex: 1;
    /* Make overflowing URL shorten  */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .screenshotImage {
    width: 100%;
  }

  .favicon {
    height: 1em;
    width: 1em;
    flex: 0 0 1em;
    margin-left: 0.5em;
  }

  &.skinny {
    .addressBar {
      margin: 0 1em;
    }
  }

  &.noBrowser {
    border-radius: 0.25em;

    .headerBar {
      display: none;
    }
  }

  &.mondrian {
    border: $mondrian-border;

    .headerBar {
      background: #fff;
      border-bottom: $mondrian-border;
      flex: 0 0 2.75em;
      border-radius: 0;
    }

    .addressBar {
      border: $mondrian-border;
    }

    .address {
      font-size: 0.875em;
      font-weight: 600;
    }

    .screenshotImage {
      border-radius: 0;
    }
  }

  &.apple {
    border-radius: 0.375em;

    .headerBar {
      border-top-left-radius: 0.375em;
      border-top-right-radius: 0.375em;
    }

    .screenshotImage {
      border-bottom-left-radius: 0.375em;
      border-bottom-right-radius: 0.375em;
    }

    .address {
      font-family: 'SF Pro Display', 'SF Pro Icons', 'TT Norms Pro',
        'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    }

    &.dark {
      .headerBar {
        background: $apple-dark;
      }

      .addressBar {
        box-shadow: inset 0px 1px 1px 0px rgba(255, 255, 255, 0.2);
        background: $apple-dark-accent;
      }

      .address {
        color: $off-white;
      }
    }

    &.light {
      .headerBar {
        background: $apple-light;
      }

      .addressBar {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.2);
      }
    }
  }

  &.windows10 {
    .addressBar {
      border-radius: 0;
    }

    .address {
      font-family: 'Segoe UI', SegoeUI, 'TT Norms Pro', 'Helvetica Neue',
        Helvetica, Arial, sans-serif;
    }

    .address.addressFull {
      padding-left: 0.5em;
    }

    &.light {
      background: $windows-light;
      border: 1px solid $windows-light-accent;

      .headerBar {
        background: $windows-light;
        border-bottom: 1px solid $windows-light-accent;
      }

      .addressBar {
        border: 1px solid $windows-light-accent;
      }

      .address.addressFull {
        border-left: 1px solid $windows-light-accent;
      }
    }

    &.dark {
      background: $windows-dark;
      border: 1px solid $windows-dark-accent;

      .headerBar {
        background: $windows-dark;
        border-bottom: 1px solid $windows-dark-accent;
      }

      .addressBar {
        border: 1px solid $windows-dark-accent;
      }

      .address.addressFull {
        border-left: 1px solid $windows-light-accent;
      }
    }
  }

  &.windows98 {
    @include border-outset;
    background: #c0c0c0;
    padding: 0 0.25em 0.25em 0.25em;

    .headerBar {
      background: #c0c0c0;
      flex: 0 0 2em;
    }

    .addressBar {
      @include border-inset;
      border-radius: 0;
      height: 1.4375em;
    }

    .favicon {
      flex: 0 0 0.8125em;
      width: 0.8125em;
      height: 0.8125em;
    }

    .address {
      font-size: 0.75em;
      font-family: 'MS Sans Serif', 'PX Sans Nouveaux', sans-serif;
    }

    .address.addressFull {
      padding-left: 0.125rem;
    }

    .webFramePlaceholder {
      @include border-inset;
      background: $off-white;
      border-width: $border-width;
    }

    .screenshotImage {
      @include border-inset;
      border-radius: 0;
      border-width: $border-width;
    }
  }
}

#export {
  width: 80%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.webFramePlaceholder {
  display: flex;
  justify-content: center;
  background: $off-white;
  width: 100%;
  height: 100%;

  .content {
    margin: 3rem;
    display: block;

    h1 {
      margin: 0 0 2rem 0;
      display: block;
      text-align: center;
      font-weight: 400;
      font-size: 2rem;
    }

    strong {
      font-weight: 600;
    }

    p {
      font-size: 1.375rem;
      line-height: 1.666;
      text-align: center;
    }
  }

  &.upload {
    background-image: linear-gradient(to right, #3498db 50%, transparent 50%),
      linear-gradient(to right, #3498db 50%, transparent 50%),
      linear-gradient(to bottom, #3498db 50%, transparent 50%),
      linear-gradient(to bottom, #3498db 50%, transparent 50%);
    background-position: left top, left bottom, left top, right top;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 28px 4px, 28px 4px, 4px 28px, 4px 28px;

    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: 150ms all;
    opacity: 0;
    visibility: hidden;

    &.active {
      opacity: 1;
      visibility: visible;
    }

    .uploadContent {
      display: flex;
      align-items: center;
    }

    .uploadDescription {
      font-size: 1.5rem;
      color: $mondrian-blue;
    }

    .uploadIcon {
      height: 1.75rem;
      width: 1.75rem;
      margin-right: 1rem;
      color: $mondrian-blue;
    }
  }
}
