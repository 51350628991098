@import 'styles/colors';
@import 'styles/general';

#header {
  border-bottom: $mondrian-border;
  display: flex;
  flex: 0 0 4rem;

  .logo {
    flex: 0 0 20rem;
    width: 20rem;
    border-right: 0.125rem solid $off-black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.25rem;
    font-family: 'Sulphur Point', 'TT Norms Pro', sans-serif;
    line-height: 1.5;
  }

  .logoIcon {
    margin-right: 1rem;
  }

  .searchForm {
    height: 100%;
    flex: 1;
    display: flex;

    .addressInput {
      font-size: 1.25rem;
      flex: 1;
      border: 0;
      outline: none;
      box-sizing: border-box;
      padding: 1rem 1.5rem;

      &::-webkit-search-decoration,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration,
      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }

      &::-webkit-search-cancel-button {
        cursor: pointer;
        height: 18px;
        width: 18px;
        background-image: url('./clear-input.svg');
      }
    }

    .goButton {
      height: 100%;
      font-size: 1.625rem;
      font-weight: 500;
      border: 0;
      flex: 0 0 auto;
      width: 10rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 0.125rem solid $off-black;

      .rightArrowIcon {
        margin-left: 0.5rem;
        transition: transform 180ms;
      }

      &:not(:disabled) {
        background-color: $flat-green;

        &:hover .rightArrowIcon {
          animation: bounce-right 0.4s infinite alternate;
        }
      }
    }
  }
}

@keyframes bounce-right {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(0.5rem);
  }
}

@media only screen and (max-width: 800px) {
  #header {
    .logo {
      flex: 1;
      border-right: 0;
    }

    .search-form {
      display: none;
    }
  }
}
